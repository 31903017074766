<template>
  <qlHeader>
    <div slot="logo" class="logo">
      <img :src="logo" />
      <p>青鹿教学云平台</p>
    </div>
    <template slot="list">
      <div class="item" @click="handleBack">返回</div>
      <span class="spacing"></span>
    </template>
  </qlHeader>
</template>

<script>
import logo from '@images/home/home_logo.png';
import qlHeader from '@/components/header';

export default {
  components: {
    qlHeader,
  },
  props: {
    backRouterPath: String,
  },
  data() {
    return {
      logo,
    };
  },
  methods: {
    handleBack() {
      if (this.$route.query.redirect) {
        location.href = decodeURIComponent(this.$route.query.redirect);
      } else {
        this.$router.replace(this.backRouterPath ?? '/home');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    color: $themeColor;
  }
}
.spacing {
  margin-left: 16px;
  margin-right: 16px;

  width: 1px;
  height: 20px;
  background: #ffffff;
  opacity: 0.2;
}
</style>
