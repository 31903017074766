<template>
  <div class="sidebar">
    <Menu
      :default-active="activeRoute"
      unique-opened
      router
      background-color="#fff"
      text-color="#2C2F2E"
      active-text-color="#38C3A1"
    >
      <MenuItem v-for="item in menuList" :key="item.path" :index="item.path" :route="item.route">
        <svg class="mg-r-10 fz-20 icon" aria-hidden="true">
          <use :xlink:href="`#` + item.icon"></use>
        </svg>
        <span slot="title">{{ item.title }}</span>
      </MenuItem>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuItem } from 'element-ui';
import userRouter from '@/router/modules/user';

export default {
  components: {
    Menu,
    MenuItem,
  },
  data() {
    return {
      menuList: [],
      activeRoute: undefined,
    };
  },
  watch: {
    '$route.path': {
      handler: function (val) {
        this.activeRoute = val;
      },
      immediate: true,
    },
  },
  methods: {
    async getUsableMenu(query) {
      this.menuList = userRouter.children.map(({ path, meta: { title, icon } }) => {
        path = `${userRouter.path}/${path}`;
        return {
          path,
          route: { path, query },
          title,
          icon,
        };
      });
    },
  },

  created() {
    this.getUsableMenu(this.$route.query);
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  width: 240px;
  background-color: #ffffff;
  flex-shrink: 0;
  padding-top: 16px;

  .el-menu-item {
    border-left: 4px solid #ffffff;

    &.is-active,
    &:hover {
      color: $themeColor !important;
      background-color: #f1fffc !important;
      border-left: 4px solid $themeColor;
    }
  }
}
</style>
