<template>
  <div class="user">
    <Header :backRouterPath="backRouterPath"></Header>
    <div class="user-main-wrapper">
      <SideBar></SideBar>
      <div class="user-main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 用户页
 */
import Header from './components/header';
import SideBar from './components/sidebar';

export default {
  name: 'user',
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
      backRouterPath: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.backRouterPath = from.fullPath;
    });
  },
};
</script>

<style lang="scss" scoped src="./user.scss"></style>
